:root {
  font-size: 16px !important;
}

@media screen and (max-width: 1150px),
screen and (max-height: 500px) {
  :root {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 900px),
screen and (max-height: 500px) {
  :root {
    font-size: 12px !important;
  }
}