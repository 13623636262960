.text-row {
  height: max-content;
}

.text-row h2 {
  font-size: 2.2rem;
  margin-bottom: 0.8rem;
}

.text-row p {
  font-size: 1.2rem;
  text-align: justify;
  text-indent: 4rem;
}
