.loginButton {
  padding: 1rem 2rem;
  border-radius: 0.4rem;
  font-size: 1rem;
  color: var(--text-color1);
  font-weight: 600;
  background-color: rgba(var(--accent-color1), 1);
}

.loginButton:hover {
  color: var(--text-color0);
  background-color: rgba(var(--accent-color0), 0.8);
}

.loginButton:active {
  transform: translateY(0.1rem);
  transition: transform 0.01s ease;
}

.loginButton svg {
  font-size: 0rem;
  color: var(--text-color0);
  opacity: 0;
}

.loginButton:hover svg {
  font-size: 1.2rem;
  opacity: 1;
}