@keyframes show_section {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
  
@keyframes animation_loading_dots {
  0%   {
    transform: translateY(0)  scale(1);
  }
  4%   {
    transform: translateY(22%) scale(1.2);
  }
  25% {
    transform: translateY(-100%) scale(1.4);
  }
  50%  {
    transform: translateY(0)  scale(1);
  }
}