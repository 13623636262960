.customFormStyle input, .customFormStyle textarea, .customFormStyle label {
  background-color: var(--background-color1);
  border: 0;
  color: var(--text-color0) !important;
  font-size: 1.1rem;
  border-radius: 0.4rem;
  padding: 1rem;
  font-family: var(--Raleway);
  width: 100%;
}

.customFormStyle label {
  color: var(--text-color2) !important;
}

.customFormStyle input[type="submit"] {
  background-color: rgba(var(--accent-color1), 0.7) !important;
  color: #ffffff !important;
  font-weight: 600;
  /* width: max-content; */
  width: 100%;
  padding: 1rem 3rem;
  align-self: flex-end;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.customFormStyle input::placeholder {
  left: 0rem;
  transition: all 500ms cubic-bezier(0.67, 0.01, 0.34, 0.99);
}

.customFormStyle input:focus::placeholder {
  color: rgba(0, 0, 0, 0);
  left: -2rem;
  position: relative;
}


/* inverted bg theme */
.invertedBgTheme input, .invertedBgTheme textarea, .invertedBgTheme label {
  background-color: var(--background-color0) !important;
}