.white-mode {
  --background-color0: #fff;
  --background-color1: #f3f5fc;
  --background-color2: #dfe2ec;

  --accent-color0: 226,182,120; /* dourado */
  --accent-color1: 166,167,153; /* fendi */

  --text-color0: #1e1e1e;
  --text-color1: #394147;
  --text-color2: #4f565c;

  --navbar-color: #1e1e1e;

  --box-shadow1: rgba(17, 12, 46, 0.15) 0px 28px 50px 0px;
}
